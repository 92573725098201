import { ButtonHTMLAttributes, ElementType } from 'react';
import { clsx } from 'clsx';
import { ComponentSizes, ButtonVariants, type ButtonVariantType } from '@shared/lib/constants';
import { Typography } from '@shared/ui';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantType;
  size?: keyof typeof ComponentSizes;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  text?: string;
  to?: string;
  icon?: ElementType;
  as?: ElementType;
  type?: 'button' | 'submit' | 'reset';
}

export const Button = ({
  variant = ButtonVariants.primary,
  size = ComponentSizes.md,
  text,
  children,
  isLoading,
  isDisabled,
  className,
  onClick,
  as: Component = 'button',
  to,
  ...rest
}: ButtonProps) => {
  const disabled = isDisabled || isLoading;
  const textContent = children || text;

  const buttonClasses = clsx(className, styles.root, styles[variant], styles[size]);

  return (
    <Component
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}
      to={to}
      {...rest}
    >
      <Typography
        tag='div'
        variant='secondary'
        size='sm'
        bold
      >
        {isLoading && <div className={styles.loader} />}
        {textContent}
      </Typography>
    </Component>
  );
};
