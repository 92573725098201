import cn from 'classnames';
import { Icons } from '@assets/icons';
import { Typography } from '@shared/ui';
import { Directions, type DirectionType } from '@shared/lib/constants';

import styles from './StatsCard.module.scss';

interface StatsCardProps {
  value: number;
  direction: DirectionType;
}

export const StatsCard = ({ value, direction }: StatsCardProps) => {
  const statsCardClasses = cn(styles.root, {
    [styles.green]: direction === Directions.up,
    [styles.red]: direction === Directions.down,
  });

  const arrowClasses = cn(styles.arrow, {
    [styles.up]: direction === Directions.up,
    [styles.down]: direction === Directions.down,
  });

  return (
    <div className={statsCardClasses}>
      <Typography
        tag='span'
        color='green'
        size='sm'
      >
        Текст
      </Typography>
      <div className={styles.content}>
        <Icons.ArrowTop className={arrowClasses} />
        <Typography
          color='green'
          tag='span'
          size='sm'
        >
          {value}%
        </Typography>
      </div>
    </div>
  );
};

export default StatsCard;
