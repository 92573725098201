import { NavLink } from '@shared/ui';
import { useNavLinks } from '@shared/hooks/useNavLinks';
import styles from './NavLinks.module.scss';

export const NavLinks = () => {
  const navLinks = useNavLinks();
  return (
    <div className={styles.root}>
      {navLinks.map((link) => (
        <NavLink
          className={styles.link}
          key={link.to}
          to={link.to}
          icon={link.icon}
          showArrow={link.showArrow}
          disabled={link.disabled}
        >
          {link.title}
        </NavLink>
      ))}
    </div>
  );
};
