import { Avatar } from '@shared/ui';
import { IUser } from '@shared/lib/types';

import styles from './UserMenuTooltipHeader.module.scss';

interface UserMenuTooltipHeaderProps {
  user: IUser;
}

export const UserMenuTooltipHeader = ({ user }: UserMenuTooltipHeaderProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.userInfo}>
          <span className={styles.name}>{user.name}</span>
          <span className={styles.email}>{user.email}</span>
        </div>

        <Avatar
          className={styles.avatar}
          name={user.name}
          status={user.status}
        />
      </div>
    </div>
  );
};
