import { useTranslation } from 'react-i18next';
import { useNotifications } from '@application/providers/NotificationProvider';

import { Notifications } from '@shared/lib/constants';

export const useToggleNotifications = () => {
  const { t } = useTranslation('global', { keyPrefix: 'notifications' });
  const { pushNotification, toggleNotifications, isNotificationsEnabled } = useNotifications();

  const onToggleNotifications = () => {
    toggleNotifications();

    const notificationMessage = isNotificationsEnabled ? t('disableNotifications') : t('enableNotifications');

    pushNotification({
      type: Notifications.DEFAULT,
      message: notificationMessage,
      options: {
        autoHideDuration: 1000,
        preventDuplicate: true,
      },
      important: true,
    });
  };

  return { onToggleNotifications, isNotificationsEnabled };
};
