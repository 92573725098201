import { UserProfile, UserEdit } from '@modules/profile';
import { useQueryParams } from '@shared/hooks/useQueryParams';
import { useUserStore } from '@store/user/useUserStore';
import { ROUTES } from '@shared/lib/constants';

import styles from './Profile.module.scss';

export const Profile = () => {
  // TODO: Вынести бизнес-логику в отдельный хук 'useUser'
  // TODO: Разкоментить, когда будет готово API
  // const { user, isLoading, error } = useUser();
  const { user } = useUserStore();

  const isCurrentUser = location.pathname === ROUTES.PROFILE;
  const editQuery = useQueryParams('edit');

  const isEditMode = Boolean(editQuery);

  // TODO: Обработать случай, когда юзера нет в store,
  //       Создать прелоадер в @shared/ui
  //       Скорее всего вместо загрузки будет заглушка "Placeholder" для UI
  //       Пока что нет времени на это, поэтому такой костыль

  if (!user) return null;

  if (isEditMode) {
    return <UserEdit />;
  }

  return (
    <div className={styles.root}>
      <UserProfile
        user={user}
        isCurrentUser={isCurrentUser}
      />
    </div>
  );
};
