import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { QueryProvider } from '@application/providers/QueryProvider';
import { NotificationProvider, SnackbarProvider } from '@application/providers/NotificationProvider';
import { UserProvider } from '@application/providers/UserProvider';

import { initI18n, configureAxios, initSentry, router } from '@application/config';

import '@application/styles/proxy_index.scss';

initI18n();
configureAxios();
initSentry();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryProvider>
      <SnackbarProvider>
        <NotificationProvider>
          <UserProvider>
            <RouterProvider router={router} />
          </UserProvider>
        </NotificationProvider>
      </SnackbarProvider>
    </QueryProvider>
  </StrictMode>,
);
