import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

import { ROUTES, Notifications } from '@shared/lib/constants';
import { fakeRequest } from '@shared/lib/utils/fakeRequest';

import { useUserContext } from '@application/providers/UserProvider';
import { useNotifications } from '@application/providers/NotificationProvider';

interface UseLoginProps {
  onClose: () => void;
}

export const useAuth = (props: UseLoginProps) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { setMockUser } = useUserContext();
  const { pushNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);

  const onLogin = async (data: FieldValues) => {
    console.log(data);
    setIsLoading(true);

    try {
      // TODO: Поменять на нормальный запрос AuthService.login
      // TODO: Добавить форму с логином и паролем
      // await AuthService.login(formdata);

      await fakeRequest(500);
      navigate(ROUTES.PROFILE);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      props.onClose();
      setMockUser();
      pushNotification({ message: t('notifications.login'), type: Notifications.SUCCESS });
    }
  };

  const onRegister = async (data: FieldValues) => {
    console.log(data);
    setIsLoading(true);

    try {
      // TODO: Поменять на нормальный запрос AuthService.register
      // TODO: Добавить форму с логином и паролем
      // await AuthService.register(formdata);

      await fakeRequest(500);
      navigate(ROUTES.PROFILE);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      props.onClose();
      setMockUser();
      pushNotification({ message: t('notifications.register'), type: Notifications.SUCCESS });
    }
  };

  return {
    isLoading,
    onLogin,
    onRegister,
  };
};
