import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { Modal } from '@components/Modal';

import { Button, Typography } from '@shared/ui';
import { ModalProps } from '@shared/hooks/useModal';
import { ComponentSizes, ButtonVariants } from '@shared/lib/constants';

import { LoginForm } from '../LoginForm/LoginForm';
import { useAuth } from '../../hooks/useAuth';

import styles from './LoginModal.module.scss';

interface LoginModalProps extends ModalProps {
  onClose: () => void;
}

export const LoginModal = (props: LoginModalProps) => {
  const { t } = useTranslation('auth', { keyPrefix: 'login' });

  const { isLoading, onLogin } = useAuth(props);

  const onSubmit = (data: FieldValues) => {
    onLogin(data);
  };

  return (
    <Modal
      {...props}
      size={ComponentSizes.sm}
    >
      <Modal.Title>
        <Typography
          variant='primary'
          align='center'
          size={ComponentSizes.lg}
        >
          {t('title')}
        </Typography>
      </Modal.Title>
      <LoginForm onSubmit={onSubmit}>
        <Modal.Footer>
          <Button
            className={styles.button}
            size={ComponentSizes.md}
            variant={ButtonVariants.secondary}
            onClick={props.onClose}
            type='button'
          >
            {t('cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            className={styles.button}
            size={ComponentSizes.md}
            variant={ButtonVariants.primary}
            type='submit'
          >
            {t('login')}
          </Button>
        </Modal.Footer>
      </LoginForm>
    </Modal>
  );
};
