import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import RussianTranslation from '@application/translations/ru.json';
import EnglishTranslation from '@application/translations/en.json';

export const initI18n = () => {
  try {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        lng: 'ru',
        supportedLngs: ['en', 'ru'],
        resources: {
          en: EnglishTranslation,
          ru: RussianTranslation,
        },
        fallbackLng: 'en',
      });

    console.info('i18n initialized');
  } catch (error) {
    console.error('i18n is not initialized', error);
  }
};
