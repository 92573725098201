import { useTranslation } from 'react-i18next';
import { ROUTES } from '@shared/lib/constants/routes';
import { Icons } from '@assets/icons';

export const useNavLinks = () => {
  const { t } = useTranslation('sidebar');

  const navLinks = [
    {
      to: ROUTES.HOME,
      title: t('home'),
      icon: Icons.Home,
    },
    {
      to: ROUTES.CHATS,
      title: t('chats'),
      icon: Icons.Messages,
    },
    {
      to: ROUTES.POSTS,
      title: t('posts'),
      icon: Icons.Posts,
    },
    {
      to: ROUTES.COMMUNITY,
      title: t('community'),
      icon: Icons.Users,
    },
    {
      to: ROUTES.NEWS,
      title: t('news'),
      icon: Icons.News,
    },
    {
      to: ROUTES.OTHER,
      title: t('other'),
      icon: Icons.Categories,
      disabled: true,
      showArrow: true,
    },
  ];

  return navLinks;
};
