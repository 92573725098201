import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { PropsWithClassName } from '@shared/lib/types';

import styles from './LoginForm.module.scss';

interface LoginFormProps extends PropsWithClassName, PropsWithChildren {
  onSubmit: SubmitHandler<FieldValues>;
}

// TODO: Добавить переиспользуемый компонент для форм в @components
export const LoginForm = ({ className, children, onSubmit }: LoginFormProps) => {
  const { handleSubmit } = useForm();

  return (
    <form
      className={cn(styles.form, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {children}
    </form>
  );
};
