import cn from 'classnames';

import { PropsWithClassName } from '@shared/lib/types';
import { Icons } from '@assets/icons';
import { NavLink } from '@shared/ui';
import styles from './SocialNetworks.module.scss';

const SOCIAL_NETWORKS_ICON_MAP = [
  {
    icon: Icons.Telegram,
    link: 'https://t.me',
    id: 'telegram',
  },
  {
    icon: Icons.Discord,
    link: 'https://discord.com',
    id: 'discord',
  },
  {
    icon: Icons.YouTube,
    link: 'https://www.youtube.com',
    id: 'youtube',
  },
];
export const SocialNetworks = ({ className }: PropsWithClassName) => {
  return (
    <div className={cn(styles.root, className)}>
      {SOCIAL_NETWORKS_ICON_MAP.map(({ icon, link, id }) => (
        <p
          className={styles.linkWrapper}
          key={id}
        >
          <NavLink
            className={styles.link}
            to={link}
            key={link}
            icon={icon}
            target='_blank'
          />
        </p>
      ))}
    </div>
  );
};
