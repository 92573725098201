import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchStore } from '@store/search/useSearchStore';

import { Icons } from '@assets/icons';
import { Input } from '@shared/ui';

import styles from './SearchInput.module.scss';

export const SearchInput = () => {
  const { t } = useTranslation('header');
  const { setSearchValue, searchValue } = useSearchStore();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Input
      className={styles.root}
      icon={Icons.Search}
      value={searchValue}
      placeholder={t('search.placeholder')}
      onChange={handleSearch}
    />
  );
};
