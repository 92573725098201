import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import i18n from 'i18next';

import { Language, ISettings } from '@shared/lib/types';

interface SettingsActions {
  toggleNotifications: VoidFunction;
  toggleAudio: VoidFunction;
  setImportantOnly: (value: boolean) => void;
  setLanguage: (language: Language) => void;
}

type SettingsStore = ISettings & SettingsActions;

const initialSettings: ISettings = {
  notifications: {
    enabled: true,
    importantOnly: false,
  },
  audio: {
    enabled: true,
  },
  language: 'en',
};

export const useSettingsStore = create<SettingsStore>()(
  immer(
    persist(
      devtools((set) => ({
        ...initialSettings,

        toggleNotifications: () =>
          set((state) => {
            state.notifications.enabled = !state.notifications.enabled;
          }),

        setImportantOnly: (value) =>
          set((state) => {
            state.notifications.importantOnly = value;
          }),

        toggleAudio: () =>
          set((state) => {
            state.audio.enabled = !state.audio.enabled;
          }),

        setLanguage: (language) =>
          set((state) => {
            i18n.changeLanguage(language);
            state.language = language;
          }),
      })),
      {
        name: 'settings-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export const useNotificationSettings = () =>
  useSettingsStore((state) => ({
    enabled: state.notifications.enabled,
    importantOnly: state.notifications.importantOnly,
    toggleNotifications: state.toggleNotifications,
  }));

export const useAudioSettings = () =>
  useSettingsStore((state) => ({
    enabled: state.audio.enabled,
    toggleAudio: state.toggleAudio,
  }));

export const useLanguage = () =>
  useSettingsStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));
