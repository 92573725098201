import { createPortal } from 'react-dom';

import { PropsWithChildren } from 'react';

interface PortalProps extends PropsWithChildren {
  target: string;
}

export const Portal = ({ target, children }: PortalProps) => {
  return createPortal(children, document.getElementById(target)!);
};
