import { FC, SVGProps } from 'react';

export enum OptionTypes {
  LINK = 'link',
  BUTTON = 'button',
}

export type OptionType = OptionTypes.LINK | OptionTypes.BUTTON;

export type OptionVariant = 'red' | 'dark' | 'white';

export type OptionProps = {
  icon: FC<SVGProps<SVGSVGElement>>;
  type: OptionType;
  link?: string;
  callback?: () => void;
  text: string;
  variant?: OptionVariant;
};

export type MenuOptions = Record<string, OptionProps>;
