import { PropsWithChildren } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

// TODO: Uncomment and use custom components for notifications
// TODO: Add custom styles for notifications

// import {
//   InfoNotification,
//   ErrorNotification,
//   SuccessNotification,
//   WarningNotification,
//   DefaultNotification,
// } from './notifications';

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  return (
    <NotistackSnackbarProvider
      Components={
        {
          // info: InfoNotification,
          // success: SuccessNotification,
          // error: ErrorNotification,
          // warning: WarningNotification,
          // default: DefaultNotification,
        }
      }
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
