import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionTypes, type MenuOptions } from '../UserMenuTooltipTypes';
import { Icons } from '@assets/icons';

import { useUserContext } from '@application/providers/UserProvider';
import { ROUTES } from '@shared/lib/constants';

export const useTooltip = () => {
  const { t } = useTranslation('header');

  const { onLogout } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  const onToggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  const onOpenTooltip = () => {
    setIsOpen(true);
  };

  const onCloseTooltip = () => {
    setIsOpen(false);
  };

  const MENU_OPTIONS_MAP: MenuOptions = {
    profile: {
      icon: Icons.User,
      type: OptionTypes.LINK,
      link: ROUTES.PROFILE,
      text: t('menu.profile'),
    },
    edit: {
      icon: Icons.Pencil,
      type: OptionTypes.LINK,
      link: ROUTES.PROFILE + '?edit',
      text: t('menu.edit'),
    },
    help: {
      icon: Icons.Info,
      type: OptionTypes.LINK,
      link: ROUTES.HELP,
      text: t('menu.help'),
    },
    logout: {
      icon: Icons.Logout,
      type: OptionTypes.LINK,
      callback: onLogout,
      variant: 'red',
      text: t('menu.logout'),
    },
  };

  return { isOpen, MENU_OPTIONS_MAP, onCloseTooltip, onOpenTooltip, onToggleTooltip };
};
