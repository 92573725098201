import { NavLink } from '@shared/ui';
import styles from './NavLinks.module.scss';

export const NavLinks = () => {
  const navLinks = Array.from({ length: 5 }, () => ({
    to: `https://workwebxpanel.com`,
    title: `Текст`,
  }));

  return (
    <div className={styles.root}>
      {navLinks.map((link, index) => (
        <NavLink
          className={styles.link}
          key={index}
          to={link.to}
          target='_blank'
        >
          {link.title}
        </NavLink>
      ))}
    </div>
  );
};
