import { motion } from 'framer-motion';
import { IUser } from '@shared/lib/types';
import { Typography, Avatar } from '@shared/ui';
import { UserActions } from './ui/UserActions/UserActions';
import styles from './UserProfile.module.scss';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

interface UserProfileProps {
  user: IUser;
  isCurrentUser: boolean;
}

export const UserProfile = ({ user, isCurrentUser }: UserProfileProps) => {
  return (
    <motion.div
      key={user.id}
      initial='hidden'
      animate='visible'
      variants={fadeIn}
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.user}>
          <Avatar
            url={user.avatarUrl}
            name={user.name}
            status={user.status}
            size='xl'
          />
          <div className={styles.info}>
            <Typography
              className={styles.name}
              size='md'
            >
              {user.name}
            </Typography>
            <Typography
              className={styles.email}
              variant='secondary'
              size='sm'
            >
              {user.email}
            </Typography>
          </div>
        </div>

        <UserActions
          isCurrentUser={isCurrentUser}
          userId={user.id}
        />
      </div>
    </motion.div>
  );
};
