import { createContext, useContext } from 'react';
import { type IUser, type Nullable } from '@shared/lib/types';
import { type UserState } from '@store/user/useUserStore';

export interface UserContextType extends Omit<UserState, 'user'> {
  isLoading: boolean;
  error: Nullable<Error>;
  user: Nullable<IUser>;
  setUser: (user: Nullable<IUser>) => void;
  onLogout: VoidFunction;
  setMockUser: VoidFunction;
}

export const UserContext = createContext<UserContextType | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
