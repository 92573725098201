import axios, { type AxiosError, AxiosRequestConfig, type AxiosResponse } from 'axios';
import { Notifications } from '@shared/lib/constants';
const ACCEPT_LANGUAGE = localStorage.getItem('i18nextLng') || 'en';

export const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api/v1`,
  headers: {
    Authorization: localStorage.getItem('token') || '',
    'Accept-Language': ACCEPT_LANGUAGE,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  function (error) {
    const { showToastOnError } = error.config;

    if (showToastOnError && window.pushNotification) {
      window.pushNotification({ message: error.message, type: Notifications.ERROR });
    }

    return Promise.reject(error);
  },
);

/**
 * @returns {Promise<T>}
 */
export const request = async <T>(
  options: AxiosRequestConfig & { showToastOnError?: boolean },
): Promise<T> => {
  if (options.showToastOnError === undefined) options.showToastOnError = true;

  const onSuccess = (response: AxiosResponse<T>) => {
    return response?.data;
  };

  const onError = (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.response?.data);
    } else {
      console.error(error);
    }
  };

  return axiosInstance(options).then(onSuccess).catch(onError) as Promise<T>;
};
