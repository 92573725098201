import cn from 'classnames';

import { NavLink, Button } from '@shared/ui';
import {
  type OptionProps,
  OptionTypes,
} from '@modules/header/components/UserMenuTooltip/UserMenuTooltipTypes';
import styles from './UserMenuTooltipItem.module.scss';

enum OptionVariants {
  RED = 'red',
  DARK = 'dark',
  WHITE = 'white',
}

interface UserMenuTooltipItemProps extends OptionProps {
  onCloseTooltip: () => void;
}

export const UserMenuTooltipItem = ({
  icon: Icon,
  text,
  type,
  link,
  callback,
  variant,
  onCloseTooltip,
}: UserMenuTooltipItemProps) => {
  const handleClick = () => {
    onCloseTooltip();
    callback?.();
  };

  const WrapperComponent = type === OptionTypes.BUTTON ? Button : NavLink;

  const wrapperClasses = cn(styles.root, {
    [styles.button]: type === OptionTypes.BUTTON,
    [styles.link]: type === OptionTypes.LINK,
    [styles.red]: variant === OptionVariants.RED,
    [styles.white]: variant === OptionVariants.WHITE,
  });

  return (
    <WrapperComponent
      className={wrapperClasses}
      icon={Icon}
      to={link as string}
      onClick={handleClick}
      size='xs'
    >
      {text}
    </WrapperComponent>
  );
};
