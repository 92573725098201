import cn from 'classnames';

import { Icons } from '@assets/icons';
import { useToggleNotifications } from '@modules/header/hooks/useToggleNotifications';
import styles from './NotificationBell.module.scss';

export const NotificationBell = () => {
  const { onToggleNotifications, isNotificationsEnabled } = useToggleNotifications();

  const notificationBellClasses = cn(styles.root, {
    [styles.active]: isNotificationsEnabled,
  });

  return (
    <div
      className={notificationBellClasses}
      onClick={onToggleNotifications}
    >
      <Icons.Notifications
        role='button'
        className={styles.icon}
      />
    </div>
  );
};
