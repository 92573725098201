import cn from 'classnames';
import { motion } from 'framer-motion';

import { PropsWithClassName, IUser } from '@shared/lib/types';
import { ROUTES } from '@shared/lib/constants';
import { Avatar, NavLink } from '@shared/ui';

import { Logo } from '@modules/header/ui';
import { Icons } from '@assets/icons';

import { UserMenuTooltip } from '../UserMenuTooltip/UserMenuTooltip';
import { SearchInput } from '../SearchInput/SearchInput';
import { NotificationBell } from '../NotificationBell/NotificationBell';

import styles from './AuthorizedHeader.module.scss';

interface AuthorizedHeaderProps extends PropsWithClassName {
  user: IUser;
}

export const AuthorizedHeader = ({ className, user }: AuthorizedHeaderProps) => {
  const headerClasses = cn(styles.root, className);

  return (
    <motion.header
      className={headerClasses}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
    >
      <div className={styles.search}>
        <Logo />
        <SearchInput />
      </div>

      <div className={styles.user}>
        <div className={styles.actions}>
          <NotificationBell />
          <NavLink
            to={ROUTES.SETTINGS}
            activeClassName={styles.active}
            className={styles.settings}
            icon={Icons.Settings}
          />
        </div>

        <UserMenuTooltip user={user} />
        <Avatar
          name={user.name}
          url={user.avatarUrl}
          status={user.status}
          size='md'
        />
      </div>
    </motion.header>
  );
};
