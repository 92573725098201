import clsx from 'clsx';
import { CSSProperties } from 'react';
import { generateBackgroundColor } from '@shared/lib/utils/generateBackgroundColor';
import { getNameInitials } from '@shared/lib/utils/getUserInitials';
import type { PropsWithClassName, UserStatus } from '@shared/lib/types';
import styles from './Avatar.module.scss';

interface AvatarProps extends PropsWithClassName {
  name: string;
  status: UserStatus;
  url?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const Avatar = ({ name, url, status, size = 'md', className }: AvatarProps) => {
  const initials: string = getNameInitials(name);
  const avatarStyle: CSSProperties = generateBackgroundColor({ name });

  const avatarClasses = clsx(styles.circle, styles[size], className, {
    [styles.online]: status === 'online',
    [styles.offline]: status === 'offline',
    [styles.away]: status === 'away',
    [styles.busy]: status === 'busy',
  });

  if (url) {
    return (
      <div className={avatarClasses}>
        <div className={styles.photo}>
          <img
            src={url}
            alt='avatar'
          />
        </div>
      </div>
    );
  }

  return (
    <div
      style={avatarStyle}
      className={clsx(styles.circle, styles.initials, avatarClasses)}
      data-content={initials}
    />
  );
};
