import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './ChatInfo.module.scss';

export const ChatInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation('chats');

  return (
    <div className={styles.root}>
      {t('item.title')} #{id}
    </div>
  );
};
