import { createContext, useContext } from 'react';
import { INotificationContext } from '@shared/lib/types';

export const NotificationContext = createContext<INotificationContext | undefined>(undefined);

export const useNotifications = (): INotificationContext => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
