import { Outlet, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { Sidebar } from '@modules/sidebar';
import { Header } from '@modules/header';

import styles from './AppLayout.module.scss';

export const AppLayout = () => {
  const location = useLocation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Header className={styles.header} />
        <Sidebar className={styles.sidebar} />
        <main className={styles.main}>
          <AnimatePresence mode='wait'>
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
            >
              <Outlet />
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};
