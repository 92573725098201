import cn from 'classnames';
import { useRef } from 'react';
import { Tooltip } from 'react-tooltip';

import { Icons } from '@assets/icons';
import { IUser } from '@shared/lib/types';
import { useClickOutside } from '@shared/hooks/useClickOutside';

import { UserMenuTooltipHeader } from './ui/UserMenuTooltipHeader/UserMenuTooltipHeader';
import { UserMenuTooltipItem } from './ui/UserMenuTooltipItem/UserMenuTooltipItem';

import { useTooltip } from './hooks/useTooltip';
import styles from './UserMenuTooltip.module.scss';

interface UserMenuTooltipProps {
  user: IUser;
}

// TODO: Сделать компонент переиспользуемым для других меню
//       Что бы можно было использовать так:
// <TooltipMenu options={options}>
//   <TooltipMenu.Content>
//     <TooltipMenu.Item />
//   </TooltipMenu.Content>
// </<TooltipMenu>

export const UserMenuTooltip = ({ user }: UserMenuTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { isOpen, MENU_OPTIONS_MAP, onCloseTooltip, onToggleTooltip } = useTooltip();

  useClickOutside({ ref: tooltipRef, callback: onCloseTooltip });

  const options = Object.values(MENU_OPTIONS_MAP);

  const triggerClasses = cn(styles.username, {
    [styles.open]: isOpen,
  });

  return (
    <div
      className={styles.root}
      ref={tooltipRef}
    >
      <span
        data-tooltip-id='profileTooltip'
        className={triggerClasses}
        onClick={onToggleTooltip}
      >
        {user.name}
        <Icons.DropdownArrow />
      </span>
      <Tooltip
        isOpen={isOpen}
        variant='dark'
        place='bottom'
        clickable={true}
        offset={30}
        className={styles.tooltip}
        id='profileTooltip'
      >
        <div className={styles.content}>
          <UserMenuTooltipHeader user={user} />
          <div className={styles.divider} />

          <div className={styles.options}>
            {options.map((option) => (
              <UserMenuTooltipItem
                onCloseTooltip={onCloseTooltip}
                key={option.text}
                {...option}
              />
            ))}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
