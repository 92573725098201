import cn from 'classnames';
import { useRef, PropsWithChildren } from 'react';

import { PropsWithClassName } from '@shared/lib/types';
import { ComponentSizes } from '@shared/lib/constants';
import { ModalProps, ModalAnimations, ModalAnimationType } from '@shared/hooks/useModal';
import { useClickOutside } from '@shared/hooks/useClickOutside';
import { Portal } from '@components/Portal/Portal';

import styles from './Modal.module.scss';

interface ModalLayoutProps extends PropsWithClassName, PropsWithChildren, ModalProps {
  onClose: () => void;
  animation: ModalAnimationType;
  size: ComponentSizes;
}

export const ModalLayout = ({ children, className, onClose, visible, animation, size }: ModalLayoutProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useClickOutside({ ref: modalRef, callback: onClose });

  const modalClasses = cn(styles.root, className, {
    [styles.fadeIn]: animation === ModalAnimations.FADE_IN,
    [styles.fadeOut]: animation === ModalAnimations.FADE_OUT,
    [styles.slideIn]: animation === ModalAnimations.SLIDE_IN,
    [styles.slideOut]: animation === ModalAnimations.SLIDE_OUT,
  });

  const modalContentClasses = cn(styles.container, styles[size]);

  if (!visible) return null;

  return (
    <Portal target='portal'>
      <div className={modalClasses}>
        <div
          className={modalContentClasses}
          ref={modalRef}
        >
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};
