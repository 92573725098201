import { useRef, useEffect, ChangeEvent, ElementType } from 'react';
import { isIOS } from 'react-device-detect';
import { clsx } from 'clsx';

import { PropsWithClassName } from '@shared/lib/types';

import styles from './Input.module.scss';

interface InputProps extends PropsWithClassName {
  icon?: ElementType;
  type?: string;
  readOnly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
}

export const Input = ({
  placeholder,
  onChange,
  value,
  className,
  readOnly,
  icon: Icon,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isIOS && inputRef.current) {
      const handleFocus = () => {
        window.scrollTo(0, document.body.scrollHeight);
      };

      const handleBlur = () => {
        window.scrollTo(0, 0);
      };

      const currentInputRef = inputRef.current;

      currentInputRef.addEventListener('focus', handleFocus);
      currentInputRef.addEventListener('blur', handleBlur);

      return () => {
        if (currentInputRef) {
          currentInputRef.removeEventListener('focus', handleFocus);
          currentInputRef.removeEventListener('blur', handleBlur);
        }
      };
    }
  }, []);

  const inputClasses = clsx(styles.root, className);

  return (
    <div
      className={inputClasses}
      {...rest}
    >
      {Icon && <Icon className={styles.icon} />}
      <input
        type={rest.type || 'text'}
        className={styles.field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};
