import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './NewsInfo.module.scss';

export const NewsInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation('news');

  return <div className={styles.root}>{t('news.item.title', { id })}</div>;
};
