import { PropsWithChildren } from 'react';
import { ModalProps } from '@shared/hooks/useModal';
import { ComponentSizes } from '@shared/lib/constants';

import { ModalLayout } from './ModalLayout';
import { ModalTitle } from './ModalTitle';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';

interface ModalComponentProps extends PropsWithChildren<ModalProps> {
  onClose: () => void;
  size: ComponentSizes;
}

const ModalComponent = ({ children, ...layoutProps }: ModalComponentProps) => {
  return <ModalLayout {...layoutProps}>{children}</ModalLayout>;
};

export const Modal = Object.assign(ModalComponent, {
  Title: ModalTitle,
  Body: ModalBody,
  Footer: ModalFooter,
});
