import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { PropsWithClassName } from '@shared/lib/types';
import { useModal } from '@shared/hooks/useModal';
import { ROUTES, ButtonVariants, ComponentSizes } from '@shared/lib/constants';
import { Button, NavLink } from '@shared/ui';
import { SocialNetworks } from '@components/SocialNetworks';
import { Icons } from '@assets/icons';

import { Logo, NavLinks } from '../../ui';
import { LoginModal } from '../../../auth/components/LoginModal/LoginModal';
import styles from './UnauthorizedHeader.module.scss';

export const UnauthorizedHeader = ({ className }: PropsWithClassName) => {
  const { t } = useTranslation('header');

  const loginModalProps = useModal();

  const headerClasses = cn(styles.root, className);

  return (
    <motion.header
      className={headerClasses}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
    >
      <div className={styles.content}>
        <Logo />
        <NavLinks />
      </div>
      <div className={styles.divider} />

      <div className={styles.content}>
        <SocialNetworks className={styles.socialNetworks} />
        <NavLink
          to={ROUTES.SEARCH}
          className={styles.search}
          icon={Icons.Search}
        />

        <Button
          variant={ButtonVariants.primary}
          to={ROUTES.HOME}
          onClick={loginModalProps.onOpen}
          size={ComponentSizes.md}
        >
          {t('login')}
        </Button>
        <NavLink
          disabled
          to={ROUTES.REGISTER}
          className={styles.register}
        >
          {t('register')}
        </NavLink>
      </div>
      <LoginModal {...loginModalProps} />
    </motion.header>
  );
};
