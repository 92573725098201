import { useForm } from 'react-hook-form';
import { IUser } from '@shared/lib/types';
import styles from './UserEdit.module.scss';

export const UserEdit = () => {
  const { register, handleSubmit } = useForm<IUser>();

  const onSubmit = (data: IUser) => {
    console.log(data);
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('name')} />
        <input {...register('email')} />
        <button type='submit'>Submit</button>
      </form>
    </div>
  );
};
