import { useState, useEffect } from 'react';

export interface ModalProps {
  visible: boolean;
  onOpen: () => void;
  onClose: () => void;
  animation: ModalAnimationType;
}

export enum ModalAnimations {
  FADE_IN = 'fadeIn',
  FADE_OUT = 'fadeOut',
  SLIDE_IN = 'slideIn',
  SLIDE_OUT = 'slideOut',
}

export type ModalAnimationType =
  | ModalAnimations.FADE_IN
  | ModalAnimations.FADE_OUT
  | ModalAnimations.SLIDE_IN
  | ModalAnimations.SLIDE_OUT;

export const useModal = (): ModalProps => {
  const [animation, setAnimation] = useState<ModalAnimationType>(ModalAnimations.FADE_IN);
  const [visible, setVisible] = useState(false);

  const onOpen = () => {
    setVisible(true);
    setAnimation(ModalAnimations.FADE_IN);
  };
  const onClose = () => {
    setAnimation(ModalAnimations.FADE_OUT);

    setTimeout(() => {
      setVisible(false);
    }, 300);
  };

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto';
  }, [visible]);

  return { visible, onOpen, onClose, animation };
};
