import cn from 'classnames';
import { ElementType, ReactNode } from 'react';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps, useLocation } from 'react-router-dom';

import { PropsWithClassName } from '@shared/lib/types';
import { Icons } from '@assets/icons';

import { Typography, type TypographyProps } from '../Typography/Typography';
import styles from './NavLink.module.scss';

export interface NavLinkProps extends RouterNavLinkProps, PropsWithClassName {
  icon?: ElementType;
  activeClassName?: string;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  showArrow?: boolean;
  size?: TypographyProps['size'];
}

export const NavLink = ({
  to,
  icon: Icon,
  children,
  className,
  activeClassName,
  disabled,
  size = 'sm',
  showArrow,
  ...rest
}: NavLinkProps) => {
  const location = useLocation();
  const isDisabled = disabled || !to || to === location.pathname;
  const activeClass = cn(styles.active, activeClassName);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isDisabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <RouterNavLink
      onClick={handleClick}
      to={to ?? location.pathname}
      aria-disabled={isDisabled}
      className={({ isActive }) =>
        cn(styles.navLink, className, {
          [activeClass]: isActive,
        })
      }
      {...rest}
    >
      {Icon && <Icon className={styles.icon} />}
      {children && (
        <Typography
          tag='span'
          size={size}
          className={styles.text}
        >
          {children}
          {showArrow && <Icons.Triangle className={styles.arrow} />}
        </Typography>
      )}
    </RouterNavLink>
  );
};
