// TODO: Унифицировать цвета иконок

import SuccessIcon from './svg/success-icon.svg?react';
import WarnIcon from './svg/warn-icon.svg?react';
import ErrorIcon from './svg/error-icon.svg?react';
import CloseCrossIcon from './svg/close-cross-icon.svg?react';

// Sidebar icons
import HomeIcon from './svg/home-icon.svg?react';
import UsersIcon from './svg/users-icon.svg?react';
import NewsIcon from './svg/news-icon.svg?react';
import MessagesIcon from './svg/messages-icon.svg?react';
import PostsIcon from './svg/posts-icon.svg?react';
import CategoriesIcon from './svg/categories-icon.svg?react';

// Header icons
import SettingsIcon from './svg/settings-icon.svg?react';
import SearchIcon from './svg/search-icon.svg?react';
import NotificationsIcon from './svg/notifications-icon.svg?react';

// Posts icons
import StarIcon from './svg/star-icon.svg?react';
import StarOutlinedIcon from './svg/star-outlined-icon.svg?react';
import LikeOutlinedIcon from './svg/like-outline-icon.svg?react';
import LikeIcon from './svg/like-filled-icon.svg?react';
import CommentIcon from './svg/comment-icon.svg?react';

// Profile icons
import CameraIcon from './svg/camera-icon.svg?react';

// Menu icons
import LogoutIcon from './svg/logout-icon.svg?react';
import InfoIcon from './svg/info-icon.svg?react';
import PencilIcon from './svg/pencil-icon.svg?react';
import UserIcon from './svg/user-icon.svg?react';

// Social icons
import TelegramIcon from './svg/telegram-icon.svg?react';
import YouTubeIcon from './svg/youtube-icon.svg?react';
import DiscordIcon from './svg/discord-icon.svg?react';

// Arrow and dropdown icons
import RefreshIcon from './svg/refresh-icon.svg?react';
import ArrowRightTopIcon from './svg/arrow-right-top-icon.svg?react';
import ArrowRightIcon from './svg/arrow-right-icon.svg?react';
import DropdownArrowIcon from './svg/dropdown-arrow-icon.svg?react';
import TriangleIcon from './svg/triangle-icon.svg?react';
import ArrowTopIcon from './svg/arrow-top-icon.svg?react';

// Other icons
import PlusSquareIcon from './svg/plus-square-icon.svg?react';
import DoneIcon from './svg/done-icon.svg?react';
import DollarIcon from './svg/dollar-icon.svg?react';

export const Icons = {
  CloseCross: CloseCrossIcon,
  Success: SuccessIcon,
  Error: ErrorIcon,
  Warn: WarnIcon,
  Info: InfoIcon,
  Categories: CategoriesIcon,
  Users: UsersIcon,
  News: NewsIcon,
  Messages: MessagesIcon,
  Posts: PostsIcon,
  Settings: SettingsIcon,
  Search: SearchIcon,
  Notifications: NotificationsIcon,
  Star: StarIcon,
  StarOutlined: StarOutlinedIcon,
  Camera: CameraIcon,
  LikeOutlined: LikeOutlinedIcon,
  LikeFilled: LikeIcon,
  Comment: CommentIcon,
  PlusSquare: PlusSquareIcon,
  Done: DoneIcon,
  ArrowRightTop: ArrowRightTopIcon,
  Dollar: DollarIcon,
  Telegram: TelegramIcon,
  YouTube: YouTubeIcon,
  Discord: DiscordIcon,
  Pencil: PencilIcon,
  Logout: LogoutIcon,
  ArrowRight: ArrowRightIcon,
  User: UserIcon,
  Refresh: RefreshIcon,
  Home: HomeIcon,
  DropdownArrow: DropdownArrowIcon,
  Triangle: TriangleIcon,
  ArrowTop: ArrowTopIcon,
};
