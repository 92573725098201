export enum Directions {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
}

export type DirectionType = keyof typeof Directions;

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export type ButtonVariantType = keyof typeof ButtonVariants;

export enum ComponentSizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}
export type ComponentSizeType = keyof typeof ComponentSizes;

export enum HorizontalAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type HorizontalAlignType = keyof typeof HorizontalAlign;

export enum VerticalAlign {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

export type VerticalAlignType = keyof typeof VerticalAlign;
