import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

import { HTMLTag, PropsWithClassName } from '@shared/lib/types';
import { ComponentSizes } from '@shared/lib/constants';

import styles from './Typography.module.scss';

// SF Pro Display
// ==================
// 	XL: font-size: 32px;
// 	LG: font-size: 22px;  (перимущественно заголовки страниц, модалок)
// 	MD: font-size: 17px;  (подзаголовки)
// 	SM: font-size: 15px;  (тайтлы в нотифаерах, тайтлы в прелоадинге и т.п.)
// 	XS: font-size: 11px;  (баттоны)

type TypographyVariants = 'primary' | 'secondary';

type TypographySizeType = keyof typeof ComponentSizes;

type TypographyColors = 'white' | 'black' | 'grey' | 'greySecondary' | 'red' | 'green';

type TypographyAlign = 'start' | 'center' | 'end';

export interface TypographyProps {
  variant?: TypographyVariants;
  size?: TypographySizeType;
  align?: TypographyAlign;
  bold?: boolean;
  color?: TypographyColors;
  tag?: HTMLTag;
}

const defaultVariantProps = {
  primary: {
    color: 'white' as TypographyColors,
    bold: true,
    size: 'md' as TypographySizeType,
    align: 'center' as TypographyAlign,
  },
  secondary: {
    color: 'grey' as TypographyColors,
    bold: false,
    size: 'md' as TypographySizeType,
    align: 'start' as TypographyAlign,
  },
};

export const Typography = ({
  variant = 'secondary',
  size,
  align,
  bold,
  color,
  tag: Tag = 'p',
  className,
  children,
}: PropsWithChildren<PropsWithClassName<TypographyProps>>) => {
  const defaults = defaultVariantProps[variant];
  const isBold = bold !== undefined ? bold : defaults.bold;

  return (
    <Tag
      className={clsx(
        styles.root,
        styles[`${variant}`],
        styles[align || defaults.align],
        styles[size || defaults.size],
        styles[color || defaults.color],
        isBold ? styles.bold : '',
        className,
      )}
    >
      {children}
    </Tag>
  );
};
