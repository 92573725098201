import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '@store/user/useUserStore';
import { Notifications } from '@shared/lib/constants';

import { type UserContextType, UserContext } from './UserContext';
import { useNotifications } from '../NotificationProvider';
// TODO: Разкоментировать когда будет сделано на бэкенде
// import { useQuery } from '@tanstack/react-query';
// import { UserService } from '@api/services/UserService';

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('global');
  const { pushNotification } = useNotifications();
  const { setUser, setMockUser, user } = useUserStore();

  // TODO: Разкоментировать получение юзера когда будет сделано на бэкенде
  // const { isLoading, error } = useQuery({
  //   queryKey: ['user/get'],
  //   queryFn: UserService.getCurrentUser,
  // });

  const onLogout = () => {
    // TODO: Добавить логику для логаута после реализации на бэкенде
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    pushNotification({ message: t('notifications.logout'), type: Notifications.DEFAULT });
  };

  const contextValue: UserContextType = {
    user,
    isLoading: false,
    error: null,
    setUser,
    onLogout,
    setMockUser,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
