import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { devtools } from 'zustand/middleware';
import { ChatTypes } from '@shared/lib/constants';

interface ISearchResult {
  id: string;
  text: string;
  chatType: keyof typeof ChatTypes;
  chatId: string;
  chatName: string;
  chatImage: string;
}

interface SearchState {
  searchValue: string;
  results: ISearchResult[];
}

interface SearchActions {
  setSearchValue: (value: string) => void;
  resetSearchValue: () => void;
  setResults: (results: ISearchResult[]) => void;
}

type SearchStore = SearchState & SearchActions;

export const useSearchStore = create<SearchStore>()(
  devtools(
    persist(
      (set) => ({
        searchValue: '',
        results: [],
        setSearchValue: (value: string) => set({ searchValue: value }),
        resetSearchValue: () => set({ searchValue: '' }),
        setResults: (results: ISearchResult[]) => set({ results }),
      }),
      {
        name: 'search-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({
          searchValue: state.searchValue,
        }),
      },
    ),
  ),
);

// Селекторы
export const useSearchValue = () => useSearchStore((state) => state.searchValue);
export const useSearchResults = () => useSearchStore((state) => state.results);
