import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { PropsWithClassName } from '@shared/lib/types';

import styles from './Modal.module.scss';

interface ModalFooterProps extends PropsWithClassName, PropsWithChildren {}

export const ModalFooter = ({ className, children }: ModalFooterProps) => {
  return <div className={cn(styles.footer, className)}>{children}</div>;
};
