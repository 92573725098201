import { AuthorizedHeader, UnauthorizedHeader } from './components';
import { useUserContext } from '@application/providers/UserProvider';

export const Header = ({ className }: { className?: string }) => {
  const { user } = useUserContext();

  if (user) {
    return (
      <AuthorizedHeader
        user={user}
        className={className}
      />
    );
  }

  return <UnauthorizedHeader className={className} />;
};
