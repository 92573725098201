import cn from 'classnames';
import { motion } from 'framer-motion';

import { PropsWithClassName } from '@shared/lib/types';
import { Typography } from '@shared/ui';
import { SocialNetworks } from '@components/SocialNetworks';
import { Icons } from '@assets/icons';
import { Directions } from '@shared/lib/constants';

import { NavLinks, StatsCard } from './ui';
import styles from './Sidebar.module.scss';

export const Sidebar = ({ className }: PropsWithClassName) => {
  const sidebarClasses = cn(styles.root, className);

  const statsValue = 1;

  return (
    <motion.aside
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      className={sidebarClasses}
    >
      <NavLinks />
      <SocialNetworks className={styles.socialNetworks} />

      <div className={styles.divider} />

      <span className={styles.balance}>
        <Icons.Dollar />
        <Typography
          className={styles.balanceText}
          tag='span'
          variant='secondary'
          size='sm'
        >
          1 000
        </Typography>
      </span>

      <StatsCard
        value={statsValue}
        direction={Directions.up}
      />
    </motion.aside>
  );
};
