import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IUser, Nullable } from '@shared/lib/types';

const MOCK_USER: IUser = {
  id: 1,
  name: 'User007',
  email: 'user007@test.com',
  avatarUrl: '',
  status: 'online',
  role: 'user',
  achievements: [],
  messages: [],
};

export interface UserState {
  user: Nullable<IUser>;
}

interface UserActions {
  setUser: (user: Nullable<IUser>) => void;
  onLogout: VoidFunction;
  setMockUser: VoidFunction;
}

type UserStore = UserState & UserActions;

export const useUserStore = create<UserStore>()(
  persist(
    immer((set) => ({
      user: null,
      setUser: (user) => set({ user }),
      onLogout: () => set({ user: null }),

      // TODO: Удалить MOCK_USER function после реализации бэкенда
      setMockUser: () => set({ user: MOCK_USER }),
    })),
    {
      name: 'user/profile',
    },
  ),
);

export const useUserSelector = () =>
  useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser,
    onLogout: state.onLogout,
    setMockUser: state.setMockUser,
  }));
