interface GenerateBackgroundColorProps {
  name: string;
}

interface GenerateBackgroundColorReturn {
  backgroundColor: string;
}

export const generateBackgroundColor = ({
  name,
}: GenerateBackgroundColorProps): GenerateBackgroundColorReturn => {
  let hash = 0;

  for (let i = 0; i < name.length; i++) {
    hash += name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 1; i < 4; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  const darken = darkenColor(color, 25);

  return {
    backgroundColor: darken,
  };
};

const darkenColor = (color: string, percent: number) => {
  const parsedColor = color.replace('#', '');
  const r = parseInt(parsedColor.substr(0, 2), 16);
  const g = parseInt(parsedColor.substr(2, 2), 16);
  const b = parseInt(parsedColor.substr(4, 2), 16);

  const darkenedR = Math.round((r * (100 - percent)) / 100);
  const darkenedG = Math.round((g * (100 - percent)) / 100);
  const darkenedB = Math.round((b * (100 - percent)) / 100);

  const darkenedColor = `rgb(${darkenedR}, ${darkenedG}, ${darkenedB})`;

  return darkenedColor;
};
