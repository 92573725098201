import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError, type ErrorResponse } from 'react-router-dom';
import { ROUTES, Notifications } from '@shared/lib/constants';
import { Button, NavLink } from '@shared/ui';
import { useNotifications } from '@application/providers/NotificationProvider';
import styles from './Error.module.scss';

export const Error = () => {
  const { t } = useTranslation('global', { keyPrefix: 'error' });

  const { pushNotification } = useNotifications();

  const error = useRouteError() as ErrorResponse;

  useEffect(() => {
    pushNotification({ message: t('title'), type: Notifications.ERROR });
  }, [error, pushNotification, t]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2>{t('title')}</h2>
        <div className={styles.errorWrapper}>
          <span className={styles.errorText}>{error.statusText || JSON.stringify(error)}</span>
        </div>
        <Button
          variant='primary'
          as={NavLink}
          to={ROUTES.HOME}
          size='md'
        >
          {t('back')}
        </Button>
      </div>
    </div>
  );
};
