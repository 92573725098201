export const ROUTES = {
  HOME: '/',
  NEWS: '/news',
  SEARCH: '/search',
  NEWS_ITEM: '/news/:id',
  POSTS: '/posts',
  POST_ITEM: '/posts/:id',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile?edit',
  LOGIN: '/login',
  REGISTER: '/register',
  SETTINGS: '/settings',
  CHATS: '/chats',
  CHAT_ITEM: '/chats/:id',
  COMMUNITY: '/community',
  MESSAGE: '/message/:id',
  NOT_FOUND: '/not-found',
  HELP: '/help',
  FAQ: '/faq',
  OTHER: '/other',
} as const;
