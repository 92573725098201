import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '@shared/lib/constants';
import { PropsWithClassName } from '@shared/lib/types';

import styles from './Logo.module.scss';

export const Logo = ({ className }: PropsWithClassName) => {
  const logoClasses = cn(styles.root, className);

  return (
    <div className={logoClasses}>
      <NavLink
        to={ROUTES.HOME}
        className={styles.logo}
      >
        <h2>LOGO</h2>
      </NavLink>
    </div>
  );
};
