import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from '@shared/lib/constants/routes';
import { AppLayout } from '@application/layout/AppLayout/AppLayout';
import {
  Error,
  Home,
  News,
  Posts,
  Profile,
  Login,
  Register,
  NewsInfo,
  PostsInfo,
  Chats,
  Community,
  ChatInfo,
  Message,
  Search,
  Settings,
  NotFound,
  Help,
} from '@pages/index';

export const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME,
      element: <AppLayout />,
      errorElement: <Error />,
      children: [
        {
          path: ROUTES.HOME,
          element: <Home />,
        },
        {
          path: ROUTES.LOGIN,
          element: <Login />,
        },
        {
          path: ROUTES.REGISTER,
          element: <Register />,
        },
        {
          path: ROUTES.NEWS,
          element: <News />,
        },
        {
          path: ROUTES.NEWS_ITEM,
          element: <NewsInfo />,
        },
        {
          path: ROUTES.POSTS,
          element: <Posts />,
        },
        {
          path: ROUTES.POST_ITEM,
          element: <PostsInfo />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
        {
          path: ROUTES.CHATS,
          element: <Chats />,
        },
        {
          path: ROUTES.CHAT_ITEM,
          element: <ChatInfo />,
        },
        {
          path: ROUTES.MESSAGE,
          element: <Message />,
        },
        {
          path: ROUTES.COMMUNITY,
          element: <Community />,
        },
        {
          path: ROUTES.SEARCH,
          element: <Search />,
        },
        {
          path: ROUTES.SETTINGS,
          element: <Settings />,
        },
        {
          path: ROUTES.NOT_FOUND,
          element: <NotFound />,
        },
        {
          path: ROUTES.HELP,
          element: <Help />,
        },
        // 404
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL || '',
  },
);
