import { type PropsWithChildren, useCallback, useEffect } from 'react';
import { useSnackbar, OptionsObject } from 'notistack';

import { Notifications, VerticalAlign, HorizontalAlign } from '@shared/lib/constants';
import { NotifyFunction, NotifyProps } from '@shared/lib/types';
import { NotificationContext } from './NotificationContext';
import { useNotificationSettings } from '@store/settings/useSettingsStore';

interface NotificationOptions extends OptionsObject {
  important?: boolean;
}

const DEFAULT_OPTIONS: NotificationOptions = {
  variant: Notifications.INFO,
  anchorOrigin: {
    vertical: VerticalAlign.top,
    horizontal: HorizontalAlign.center,
  },
  autoHideDuration: 2000,
};

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const { enabled, importantOnly, toggleNotifications } = useNotificationSettings();
  const { enqueueSnackbar } = useSnackbar();

  const canNotify = useCallback(
    ({ important }: NotifyProps) => {
      if (important) return true;
      return enabled && !importantOnly;
    },
    [enabled, importantOnly],
  );

  const notify: NotifyFunction = useCallback(
    ({ message = '', type = Notifications.INFO, options = {} }) => {
      return enqueueSnackbar(message, {
        ...DEFAULT_OPTIONS,
        ...options,
        variant: type,
      });
    },
    [enqueueSnackbar],
  );

  const pushNotification = useCallback(
    (notification: NotifyProps) => {
      if (canNotify(notification)) {
        notify(notification);
      }
    },
    [notify, canNotify],
  );

  useEffect(() => {
    window.pushNotification = notify;
    return () => {
      window.pushNotification = null;
    };
  }, [notify]);

  return (
    <NotificationContext.Provider
      value={{
        pushNotification,
        toggleNotifications,
        isNotificationsEnabled: enabled,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
