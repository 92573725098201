import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { PropsWithClassName } from '@shared/lib/types';

import styles from './Modal.module.scss';

interface ModalTitleProps extends PropsWithClassName, PropsWithChildren {}

export const ModalTitle = ({ className, children }: ModalTitleProps) => {
  return <div className={cn(styles.title, className)}>{children}</div>;
};
