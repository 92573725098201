import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, NavLink, Typography } from '@shared/ui';
import { Icons } from '@assets/icons';
import { ROUTES, ComponentSizes } from '@shared/lib/constants';

import styles from './UserActions.module.scss';

interface UserActionsProps {
  isCurrentUser: boolean;
  userId: number;
}

export const UserActions = ({ isCurrentUser, userId }: UserActionsProps) => {
  const { t } = useTranslation('profile');
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(ROUTES.PROFILE_EDIT);
  };

  const handleMessagesClick = () => {
    navigate(`/chats/${userId}`);
  };

  if (isCurrentUser) {
    return (
      <div className={styles.root}>
        <Button
          className={styles.button}
          size={ComponentSizes.md}
          to={ROUTES.PROFILE_EDIT}
          onClick={handleEditClick}
        >
          <Typography
            className={styles.text}
            size='xs'
            bold
          >
            {t('actions.edit')}
          </Typography>
          <Icons.Pencil />
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <NavLink
        to={`${ROUTES.CHATS}/${userId}`}
        onClick={handleMessagesClick}
      >
        <Icons.Messages />
      </NavLink>
    </div>
  );
};
